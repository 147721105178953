import { FC } from 'react';
import s from "./UI.module.scss";
import {icons} from "../../../icons/icons";

const Timer: FC<IProps> = ({ className, days, hours, minutes, seconds }) => {
    const formatValue = (value: number) =>
        value < 10 ? '0' + value : value.toString()

    return (
        <div className={className}>
            <p className={s.title}>Time left till the end of the season :</p>
            <div className={s.timer}>
                <div className={s.item}>
                    <p>{formatValue(days)}</p>
                    <span>Days</span>
                </div>
                {icons.splitter}
                <div className={s.item}>
                    <p>{formatValue(hours)}</p>
                    <span>Hours</span>
                </div>
                {icons.splitter}
                <div className={s.item}>
                    <p>{formatValue(minutes)}</p>
                    <span>Minutes</span>
                </div>
                {icons.splitter}
                <div className={s.item}>
                    <p>{formatValue(seconds)}</p>
                    <span>Seconds</span>
                </div>
            </div>
        </div>
    )
}

export default Timer

interface IProps {
    className?: string
    days: number
    hours: number
    minutes: number
    seconds: number
}