import Button from 'components/Button/Button'
import s from './Action.module.scss'
import { ActionType } from 'api/leagues'
import { icons } from '../../../icons/icons'
import classNames from "classnames";

export default function ChallengeAction({
    action,
    isCompleted,
}: {
    action: ActionType
    isCompleted: boolean;
}) {
    return (
        <div className={s.action}>
            <div className={s.reward}>
                {icons.reward}
                500
            </div>

            <div className={classNames(s.content, s.content_chalenge)}>
                <p className={s.title}>Partnership Challenge</p>
                <p>Join our mission to expand our League system! Bring in projects or telegram groups to join CuickClick and receive  <b>$500</b> commission. The more partners you recruit, the greater benefits you’ll receive.</p>
            </div>

            <Button type="primary" disabled={isCompleted} onClick={() => window.open(action?.link)}>
                {isCompleted ? "Completed" : "Contact us"}
            </Button>
        </div>
    );
}
