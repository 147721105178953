import { icons } from "../../icons/icons";
import s from "./Navigation.module.scss";
import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

const tg = window.Telegram.WebApp;

function Navigation() {
  const { pathname } = useLocation()
  const navigate = useNavigate();

  const links = [
    {
      href: "/boost",
      title: "Boost",
      icon: icons.navigation.boost,
    },
    {
      href: "/leagues",
      title: "Leagues",
      icon: icons.navigation.leagues,
    },
    {
      href: "/mining",
      title: "Minings",
      icon: icons.navigation.mining,
    },
    {
      href: "/rewards",
      title: "Rewards",
      icon: icons.navigation.rewards,
    },
    {
      href: "/wallet",
      title: "Wallet",
      icon:  icons.navigation.wallet,
    },
  ]

  const onLinkChange = (href: string) => {
    tg?.HapticFeedback?.impactOccurred("soft");
    navigate(href)
  };

  return (
    <nav className={s.navigation}>
      {links.map(link => (
          <button
              key={link.title}
              onClick={() => onLinkChange(link.href)}
              className={classNames(
                  s.link,
                  { [s.active]: pathname.includes(link.href) }
              )}
          >
            {link.icon}
            {link.title}
          </button>
      ))}
    </nav>
  );
}

export default memo(Navigation);
