import { FC, ReactNode, useEffect } from 'react'
import classNames from 'classnames'
import s from './Popup.module.scss'
import { icons } from '../../icons/icons'

const Popup: FC<IProps> = ({ children, active, setActive }) => {
    useEffect(() => {
        active
            ? document.body.classList.add('overflow-y-hidden')
            : document.body.classList.remove('overflow-y-hidden')
    }, [active])

    return (
        <div
            className={classNames(s.overflow, active && s.overflow_active)}
            onClick={() => setActive(false)}
        >
            <div className={s.background} />
            <div className={s.popup} onClick={e => e.stopPropagation()}>
                <button onClick={() => setActive(false)} className={s.close}>
                    {icons.close}
                </button>
                {children}
            </div>
        </div>
    )
}

export default Popup

interface IProps {
    children: ReactNode
    active: boolean
    setActive: (value: boolean) => void
}
