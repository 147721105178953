import { memo } from "react";
import s from "./Loading.module.scss";

function Loading() {
  return (
    <div className={s.container}>
      <img className={s.img} src="/leagues/quick-click-tap.png" alt="loading" />
      <div className={s.desc}>Loading...</div>
    </div>
  );
}

export default memo(Loading);
