import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { userApi } from './services/user'
import { leagueApi } from './services/league'

const rootReducer = combineReducers({
    [userApi.reducerPath]: userApi.reducer,
    [leagueApi.reducerPath]: leagueApi.reducer,
})

export const makeStore = () => {
    return configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
            userApi.middleware,
            leagueApi.middleware,
        ),
    })
}

export type AppStore = ReturnType<typeof makeStore>
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']