import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.scss";
import Navigation from "./components/Navigation/Navigation";
import { useEffect, useRef, useState } from "react";
import Loading from "components/Loading/Loading";
import { ActionsLinks, ActionType, leaguesApi, LeagueType } from "api/leagues";
import { CommonScore, GetUserData, ScoresType, usersApi } from "api/users";
import Roadmap from "./pages/Roadmap/Roadmap";
import HowItWorks from "./pages/HowItWorks/HowItWorks";
import TapperPage from "./pages/Tapper/TapperPage";
import RewardsPage from "./pages/Rewards/RewardsPage";
import WalletPage from "./pages/Wallet/WalletPage";
import LeaguesPage from "./pages/Leagues/LeaguesPage";
import BoostPage from "./pages/Boost/BoostPage";
import DailyRewards from "./pages/DailyRewards/DailyRewards";
import MiningPage from "./pages/Mining/MiningPage";
import { useGetDailyInfoQuery } from "./store/services/user";

const tg = window.Telegram.WebApp;
const userId = tg?.initDataUnsafe?.user?.id as number;

export const LAST_DAILY_REWARD_DATE_KEY = 'lastDailyRewardDate'
const IS_NOT_FIRST_LAUNCH_KEY = 'isNotFirstLaunch'

function App() {
  const isNotFirstLaunchRef = useRef(localStorage.getItem(IS_NOT_FIRST_LAUNCH_KEY))
  const lastDailyRewardDateRef = useRef(localStorage.getItem(LAST_DAILY_REWARD_DATE_KEY))

  const [isLoading, setIsLoading] = useState(true);
  const [leagues, setLeagues] = useState<LeagueType[] | null>(null); //type
  const [activeLeague, setActiveLeague] = useState<LeagueType | null>(null); //type
  const [scores, setScores] = useState<ScoresType[]>([]);
  const [actions, setActions] = useState<ActionType[]>([]);
  const [completedActions, setCompletedActions] = useState<ActionType[]>([]);
  const [refLink, setRefLink] = useState("");
  const [userData, setUserData] = useState<GetUserData | null>(null);
  const [commonScore, setCommonScore] = useState<CommonScore | null>(null);
  const [actionsLinks, setActionsLinks] = useState<ActionsLinks | null>(null);

  const { data: currentDaily } = useGetDailyInfoQuery(userId)

  const history = useNavigate();

  //back button on tg
  const backButton = tg.BackButton;
  backButton.show();
  tg.onEvent("backButtonClicked", function () {
    history("/tapper");
  });

  useEffect(() => {
    async function getActions() {
      if (!activeLeague) return;

      setIsLoading(true);

      try {
        const [
          actionsRes,
          actionsLinksRes,
          completedActionsRes,
          scores,
          commonScore,
        ] = await Promise.all([
          leaguesApi.getActions(activeLeague.id),
          leaguesApi.getActionsLinks(userId),
          usersApi.getCompletedActions(activeLeague.id, userId),
          usersApi.getScores(userId),
          usersApi.getCommonScores(userId),
        ]);

        if (activeLeague) {
          const refLink = await usersApi.getRefLink(userId, activeLeague?.id);
          setRefLink(refLink);
        }
        if (commonScore) {
          setCommonScore(commonScore);
        }

        setScores(scores?.result);
        setCompletedActions(completedActionsRes);
        setActions(actionsRes);

        setActionsLinks(actionsLinksRes)
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
    getActions();
  }, [activeLeague]);

  useEffect(() => {
    tg.expand(); // 100% height

    if (!isNotFirstLaunchRef.current) {
      localStorage.setItem(IS_NOT_FIRST_LAUNCH_KEY, 'true')
    }

    async function getData() {

      try {
        const leagues = await leaguesApi.getLeagues();

        if (leagues?.length) {
          setLeagues(leagues);
        }
        const actLeague =
          leagues?.find((league: LeagueType) => league.id === 1) || null;

        setActiveLeague(actLeague);
      } catch (e) {
        console.log(e);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getCommonScores() {
      try {
        const userData = await usersApi.getUserData(userId);

        if (userData) {
          setUserData(userData);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getCommonScores();
  }, [userId]);


  if (process.env.NODE_ENV !== "development") {
    if (!navigator?.userAgent?.toLowerCase()?.includes("mobi")) {
      return (
          <div className="not-mobile">
            Please, open the application on your mobile device.
          </div>
      );
    }
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="App">
      <Routes>
        <Route
            path="/"
            element={
              !isNotFirstLaunchRef.current
                  ? <Roadmap />
                  : lastDailyRewardDateRef.current !== new Date().toDateString()
                    ? <DailyRewards league={activeLeague} currentDaily={currentDaily} />
                    : <Navigate to="/tapper" replace />
            }
        />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route
            path="/tapper"
            element={<TapperPage league={activeLeague} refLink={refLink} />}
        />
        <Route
          path="/boost"
          element={
            <BoostPage
              actions={actions}
              completedActions={completedActions}
              league={activeLeague}
              refLink={refLink || ""}
            />
          }
        />
        <Route
          path="/leagues"
          element={
            <LeaguesPage
              setActiveLeague={setActiveLeague}
              scores={scores}
              leagues={leagues}
              actionsLinks={actionsLinks}
            />
          }
        />
        <Route
            path="/mining"
            element={<MiningPage leagueId={activeLeague?.id!} />}
        />
        <Route
            path="/rewards"
            element={<RewardsPage league={activeLeague} />}
        />
        <Route
          path="/wallet"
          element={
            <WalletPage
              commonScore={commonScore}
              userData={userData}
            />
          }
        />
      </Routes>
      <Navigation />
    </div>
  );
}

export default App;
