import React, {useMemo, useState} from "react";
import League from "./League/League";
import s from "./Leagues.module.scss";
import { ActionsLinks, LeagueType } from "api/leagues";
import { ScoresType } from "api/users";
import LeaderboardPopup from "./League/Leaderboard/LeaderboardPopup";
import Popup from "../../components/Popup/Popup";

export default function LeaguesPage({
  leagues,
  scores,
  setActiveLeague,
  actionsLinks,
}: {
  leagues: LeagueType[] | null;
  scores: ScoresType[];
  setActiveLeague: (league: LeagueType) => void;
  actionsLinks: ActionsLinks | null;
}) {
  const [selectedLeague, setSelectedLeague] = useState<LeagueType | null>(null)
  const [isLeaderboard, setIsLeaderboard] = useState(false)

  const leagueItems = useMemo(() => {
    return leagues?.map((l) => {
      const score = scores.find((sc) => sc.league_id === l.id);
      return (
        <League
          key={l.id}
          league={l}
          scores={score}
          setActiveLeague={setActiveLeague}
          actionsLinks={actionsLinks}
          setSelectedLeague={setSelectedLeague}
          setIsLeaderboard={setIsLeaderboard}
        />
      );
    });
  }, [leagues, scores]);

  return (
    <section className={s.leagues}>
      <div className={s.head}>
        <h1>Top Trending Leagues</h1>
        <p>Earn more rewards through each leagues boost tasks</p>
      </div>
      <div className={s.grid}>
        {leagueItems}
      </div>

      <Popup active={isLeaderboard} setActive={setIsLeaderboard}>
        <LeaderboardPopup leagueId={selectedLeague?.id} />
      </Popup>
    </section>
  )
}
