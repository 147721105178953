import { FC } from 'react'
import icon from './icon.png'
import s from './DailyRewards.module.scss'
import Button from '../../components/Button/Button'
import { LeagueType } from '../../api/leagues'
import classNames from 'classnames'
import {CurrentDaily, useSaveDailyRewardMutation} from '../../store/services/user'
import {useNavigate} from "react-router-dom";
import {LAST_DAILY_REWARD_DATE_KEY} from "../../App";

const tg = window.Telegram.WebApp;
const userId = tg?.initDataUnsafe?.user?.id as number;

const rewards = [500, 1000, 2500, 5000, 10000, 25000, 50000, 100000, 250000, 500000]

const DailyRewards: FC<IProps> = ({ league, currentDaily }) => {
    const navigate = useNavigate()

    const [saveDailyReward] = useSaveDailyRewardMutation()

    const rewardDate = new Date(currentDaily?.result?.date ?? 0).setHours(0, 0, 0, 0)
    const today = new Date().setHours(0, 0, 0, 0)
    const isRewarded = rewardDate === today

    const claim = async () => {
        const { error } = await saveDailyReward({ user_id: userId, reward: currentDaily?.next_reward! })

        if (!error) {
            localStorage.setItem(LAST_DAILY_REWARD_DATE_KEY, new Date().toDateString())
            navigate('/tapper')
        }
    }

    return (
        <div>
            <div className={s.head}>
                <img src={icon} alt="icon" />
                <h1>Daily Rewards</h1>
                <p>Claim rewards by logging into the <br/>game daily-don’t miss a day!</p>
            </div>

            <div className={s.grid}>
                {rewards?.map((r, i) => (
                    <div key={r} className={classNames(
                        s.item,
                        ((currentDaily?.result?.reward ?? 0) >= r || !i) && s.item_active,
                    )}>
                        <span>Day {i + 1}</span>
                        <p>
                            <img src={league?.tap_image ?? ''} alt="league"/>
                            {r >= 1000 ? (r / 1000) + 'k' : r}
                        </p>
                    </div>
                ))}
            </div>

            <Button
                type="primary"
                onClick={claim}
                disabled={isRewarded}
            >
                {isRewarded ? 'Claimed' : 'Claim'}
            </Button>
        </div>
    )
}

export default DailyRewards

interface IProps {
    league: LeagueType | null
    currentDaily: CurrentDaily | undefined
}

