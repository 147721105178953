import { fetchBaseQuery } from '@reduxjs/toolkit/query'

const { initData } = window.Telegram.WebApp

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: headers => {
        headers.set('InitData', initData)

        if (process.env.NODE_ENV === "development") {
            const testBot = process.env.REACT_APP_TEST_BOT
            headers.set('testBot', testBot!)
        }

        return headers
    },
})
