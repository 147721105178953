import { FC } from 'react'
import s from './LeaderboardPopup.module.scss'
import { useGetLeaderboardQuery } from '../../../../store/services/league'

const LeaderboardPopup: FC<IProps> = ({ leagueId }) => {
    const { data, isFetching } = useGetLeaderboardQuery(
        leagueId!,
        { refetchOnMountOrArgChange: true, skip: !leagueId }
    )

    return (
        <div className={s.leaderboard}>
            <h1>Leaderboard</h1>

            {isFetching
                ? 'Loading...'
                : (
                    <div className={s.wrapper}>
                        {data?.result?.map((item, i) => (
                            <div key={i} className={s.item}>
                                <p className={s.number}>{i + 1}.</p>
                                <div className={s.user}>
                                    <img src="/leaderboard/avatar.png" alt="avatar"/>
                                    <div>
                                        <p>{item.username}</p>
                                        <span>Last Played: {item.last_time ?? '-'}</span>
                                    </div>
                                </div>
                                <div className={s.result}>
                                    <p>{item.score.toLocaleString()}</p>
                                    <span>points</span>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        </div>
    )
}

export default LeaderboardPopup

interface IProps {
    leagueId: number | undefined
}
