import s from "./RewardsPage.module.scss";
import { LeagueType } from "api/leagues";

export default function RewardsPage({ league }: { league: LeagueType | null }) {
  const prizes = league?.seasons[league?.seasons?.length - 1]?.prizes;

  return (
    <section className={s.rewards}>
        <div
            className={s.head}
            style={{
                backgroundImage: `url(${league?.list_cover}`,
            }}
        >
            <img src={league?.tap_image || ""} alt={league?.name}/>
            <p>{league?.name} League</p>
            <h1>Rewards</h1>
        </div>

        <div className={s.list}>
            {prizes
                ? prizes.map((item, i) => (
                    <div key={i} className={s.item}>
                      <div className={s.place}>{i + 1}st Place</div>
                      <div className={s.prize}>${item}</div>
                  </div>
              ))
              : "Prizes not found"}
      </div>
    </section>
  );
}
