import { FC } from 'react';
import s from './ConfirmPopup.module.scss'
import { icons } from '../../../icons/icons'
import Button from '../../../components/Button/Button'
import { MiningPackage } from '../MiningPage'
import { useCreateInvoiceMutation } from '../../../store/services/user'

const tg = window.Telegram.WebApp;
const userId = tg?.initDataUnsafe?.user?.id as number;

const ConfirmPopup: FC<IProps> = ({ selectedPack, leagueId, closePopup }) => {
    const [createInvoice] = useCreateInvoiceMutation()

    const pay = async (pack: MiningPackage) => {
        createInvoice({ user_id: userId, league_id: leagueId, pack: pack.id })
        closePopup()
    }

    return (
        <div className={s.popup}>
            <div className={s.balance}>
                {icons.mining.price}
                {/*<div>*/}
                {/*    <span>Balance</span>*/}
                {/*    <p>1594</p>*/}
                {/*</div>*/}
            </div>
            <div className={s.icon}>
                {selectedPack?.icon.img}
            </div>
            <div className={s.price}>
                {icons.mining.price}
                {selectedPack?.price}
            </div>
            <h1>{selectedPack?.name}</h1>
            <p className={s.text}>
                Do you want to continue with {selectedPack?.name} {selectedPack?.price} Stars?
            </p>
            <Button type="secondary" onClick={() => pay(selectedPack!)}>
                Confirm and Pay
            </Button>
        </div>
    )
}

export default ConfirmPopup

interface IProps {
    selectedPack: MiningPackage | null
    leagueId: number
    closePopup: () => void
}
