import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './api'

export const leagueApi = createApi({
    reducerPath: 'leagueApi',
    baseQuery: baseQuery,
    tagTypes: [],
    endpoints: builder => ({
        getLeaderboard: builder.query<
            { result: LeaderboardItem[] }, number
        >({
            query: league_id => ({
                url: 'leaderboard',
                params: { league_id },
            }),
        }),
    }),
})

export const {
    useGetLeaderboardQuery,
} = leagueApi

interface LeaderboardItem {
    last_time: null
    league_id: number
    league_season_id: number
    score: number
    user_id: number
    username: string
}
