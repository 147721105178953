import { FC, ReactNode, useState } from 'react'
import s from './MiningPage.module.scss'
import icon from './icon.png'
import Button from '../../components/Button/Button'
import { icons } from '../../icons/icons'
import Popup from '../../components/Popup/Popup'
import ConfirmPopup from './ConfirmPopup/ConfirmPopup'
import { useGetInvoicesQuery } from '../../store/services/user'

const packages: MiningPackage[] = [
    {
        id: 'basic',
        icon: {
            img: icons.mining.basic,
            color: 'secondary',
        },
        name: 'Basic mining Package',
        price: 500,
        description: [
            'Auto clicks for you every day',
            'Clicker daily cap up to 4000',
            'Bonus 350,000 $CLIK Token airdrop',
        ],
    },
    {
        id: 'quick',
        icon: {
            img: icons.mining.quick,
            color: 'primary',
        },
        name: 'Quick mining Package',
        price: 750,
        description: [
            'Auto clicks for you every day',
            'Clicker daily cap up to 9000',
            'Bonus 1,000,000 $CLIK Token airdrop',
        ],
    },
]

const tg = window.Telegram.WebApp
const userId = tg?.initDataUnsafe?.user?.id as number

const MiningPage: FC<IProps> = ({ leagueId }) => {
    const { data: invoices } = useGetInvoicesQuery(
        { user_id: userId, league_id: leagueId },
        { refetchOnMountOrArgChange: true },
        )

    const [selectedPack, setSelectedPack] = useState<MiningPackage | null>(null)
    const [isConfirm, setIsConfirm] = useState(false)

    const buyPackage = (pack: MiningPackage) => {
        setSelectedPack(pack)
        setIsConfirm(true)
    }

    return (
        <div className={s.mining}>
            <div className={s.head}>
                <img src={icon} alt="icon" />
                <h1>What is mining?</h1>
                <p>Automate your clicker work, have the Quick mining protocol mine the clicks for you daily!</p>
            </div>

            <div className={s.list}>
                {packages?.map(p => (
                    <div key={p.id} className={s.item}>
                        <div className={s.price}>
                            {icons.mining.price}
                            {p.price}
                        </div>
                        <Button type={p.icon.color} onClick={() => null} className={s.icon}>
                            {p.icon.img}
                        </Button>
                        <p className={s.title}>{p.name}</p>
                        <ul>
                            {p.description?.map((item, i) => (
                                <li key={i}>
                                    <span>{icons.check}</span>
                                    {item}
                                </li>
                            ))}
                        </ul>
                        <Button
                            type="primary"
                            onClick={() => buyPackage(p)}
                            disabled={!!invoices?.result}
                        >
                            Buy Package
                        </Button>
                    </div>
                ))}
            </div>

            <Popup active={isConfirm} setActive={setIsConfirm}>
                <ConfirmPopup
                    selectedPack={selectedPack}
                    leagueId={leagueId}
                    closePopup={() => setIsConfirm(false)}
                />
            </Popup>
        </div>
    )
}

export default MiningPage

export interface MiningPackage {
    id: 'basic' | 'quick'
    icon: {
        img: ReactNode
        color: 'primary' | 'secondary'
    },
    name: string
    price: number
    description: string[]
}

interface IProps {
    leagueId: number
}