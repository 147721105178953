import s from "./Action.module.scss";
import Button from "../../../components/Button/Button";
import inviteImg from "./images/invite.png";
import tapImage from "../../../icons/quick-click.png"

export default function InviteAction({
    refLink,
    isCompleted,
    reward,
}: {
    refLink: string
    isCompleted: boolean
    reward: number
}) {
    const share = () => {
        const refLinkText = `Click with me on QuickClick mini game! Over $20,000 USDT in prize money given away to highest clickers every month 🏆💵 Use the boost to earn more points 🚀 First 10,000 users get BONUS TOKEN AIRDROP!! 🎁`;
        window.open(`https://t.me/share/url?url=${refLink}&text=${encodeURIComponent(refLinkText)}`)
    }

    const copyLink = () => {
        navigator.clipboard
            .writeText(refLink)
            .then(() => {
                alert("Link copied: " + refLink);
            })
            .catch((err) => {
                console.error("Copy error: ", err);
            });
    }

    return (
        <div className={s.action}>
            <div className={s.reward}>
                {<img src={tapImage} alt="league"/>}
                {reward}
            </div>
            <div className={s.content}>
                <img src={inviteImg} alt="invite" />
                <div>
                    <p className={s.title}>Invite your friends now!</p>
                    <p>Share this with your friends and earn <b>50,000</b> points for inviting a friend.</p>
                </div>
            </div>
            <Button
                type="primary"
                onClick={share}
            >
                {isCompleted ? "Completed" : "Share with friends"}
            </Button>
            <Button
                type="secondary"
                onClick={copyLink}
                className={s.secondaryBtn}
            >
                Copy invite link
            </Button>
        </div>
    );
}
