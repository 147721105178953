import { Link } from "react-router-dom";
import s from "./League.module.scss";
import { ActionsLinks, LeagueType } from "api/leagues";
import { getElementsSum } from "utils/getElementsSum";
import { ScoresType, usersApi } from "api/users";
import { icons } from "../../../icons/icons";
import { MouseEvent } from "react";
import Button from "../../../components/Button/Button";
import tapImage from '../../../icons/quick-click.png'

const tg = window.Telegram.WebApp
const userId = tg?.initDataUnsafe?.user?.id

export default function League({
  league,
  scores,
  setActiveLeague,
  actionsLinks,
  setSelectedLeague,
  setIsLeaderboard,
}: {
  league: LeagueType
  scores: ScoresType | undefined
  setActiveLeague: (league: LeagueType) => void
  actionsLinks: ActionsLinks | null
  setSelectedLeague: (value: LeagueType | null) => void
  setIsLeaderboard: (value: boolean) => void
}) {
  const currentSeason = league.seasons[league.seasons.length - 1]

  const handleClickAction = async (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    try {
      await usersApi.performAction(
          actionsLinks?.[league.id]?.id!,
          userId,
          league.id,
      )

    } catch (e) {
      //
    } finally {
      location.href = actionsLinks?.[league.id]?.link ?? ''
    }
  }

  const openLeaderboard = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    setSelectedLeague(league)
    setIsLeaderboard(true)
  }

  return (
      <Link
          to="/tapper"
          onClick={() => setActiveLeague(league)}
          className={s.league}
      >
        <div
            className={s.head}
            style={{
              backgroundImage: `url(${league.list_cover}`,
            }}
        >
          <img src={league.tap_image ?? ''} alt={league.name} />
          <p className={s.title}>{league.name}</p>
          <p className={s.label}>Stacks</p>
        </div>

        <div className={s.body}>
          <div className={s.season}>
            <span>Season {league.seasons.length}</span>
            <p>{currentSeason.name}</p>
          </div>

          <div className={s.timing}>
            <span>Ends in:</span>
            <div>
              <div className={s.item}>
                {icons.date.calendar}
                {new Date(currentSeason.end_time).toLocaleDateString()}
              </div>
              <div className={s.item}>
                {icons.date.clock}
                {new Date(currentSeason.end_time).toLocaleTimeString()}
              </div>
            </div>
          </div>

          <div className={s.stats}>
            <div className={s.item}>
              {icons.stats.position}
              <div>
                <span>Your Place</span>
                <p>{scores?.position}</p>
              </div>
            </div>
            <div className={s.item}>
              <img src={tapImage} alt={league.name}/>
              <div>
                <span>Current Score</span>
                <p>{scores?.score}</p>
              </div>
            </div>
            <div className={s.item}>
              <div>
                <span>Prize Pool</span>
                <p>${getElementsSum(currentSeason.prizes)}</p>
              </div>
            </div>
          </div>

          <Button
              type="primary"
              onClick={handleClickAction}
              className={s.btn}
              disabled={!!actionsLinks?.[league.id]?.completedAt}
          >
            {`Join chat for ${actionsLinks?.[league.id]?.reward} points`}
          </Button>
          <Button
              type="secondary"
              onClick={openLeaderboard}
              className={s.btn}
          >
            View Leaderboard
          </Button>
        </div>
      </Link>
  )
}
