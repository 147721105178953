import { FC } from 'react';
import s from "./Stats.module.scss";
import {icons} from "../../../icons/icons";

const Stats: FC<IProps> = ({ scores, position, players, tapImage }) => {
    return (
        <div className={s.stats}>
            <div className={s.item}>
                {icons.stats.position}
                <div>
                    <span>Position</span>
                    <p>{position}</p>
                </div>
            </div>
            <div className={s.item}>
                <img src={tapImage || ''} alt="tap"/>
                <div>
                    <span>Score</span>
                    <p>{scores}</p>
                </div>
            </div>
            <div className={s.item}>
                {icons.stats.players}
                <div>
                    <span>Players</span>
                    <p>{players}</p>
                </div>
            </div>
        </div>
    )
}

export default Stats

interface IProps {
    scores: number
    position: number
    players: number
    tapImage?: string | null
}