import Button from "components/Button/Button";
import s from "./Action.module.scss";
import { ActionType } from "api/leagues";
import { usersApi } from "../../../api/users";
import { useMemo } from "react";
import tapImage from "../../../icons/quick-click.png"

export default function FollowAction({
  action,
  isCompleted,
  userId,
  refLink,
}: {
  action: ActionType
  isCompleted: boolean
  userId: number
  refLink: string
}) {
  const linkX = useMemo(
    () =>  action.link.replace(/__referal_link__/g, encodeURIComponent(refLink)),
    [],
  )

  function getFirstWord(str: string) {
    if (!str) return ""; // Проверяем, что строка не пустая
    const words = str.split(" "); // Разделяем строку по пробелам
    return words[0]; // Возвращаем первое слово
  }

  async function handleClickAction() {
    try {
      await usersApi.performAction(action.id, userId, action.league_id);
    } catch (e) {
      //
    }
  }

  return (
      <div className={s.action}>
        <div className={s.reward}>
          {<img src={tapImage} alt="league"/>}
          {action.reward}
        </div>

        <div className={s.content}>
          <img src={action.image} alt="logo" />
          <div>
            <p className={s.title}>{action.name}</p>
            <p>{action?.name} to earn points</p>
          </div>
        </div>

        {action.code === "share_x" ? (
            <Button
                type="primary"
                disabled={isCompleted}
                onClick={() => {
                  window.open(linkX)
                  handleClickAction()
                }}
            >
              {/* {<img src="/leagues/test.png" alt="" />} */}
              {isCompleted ? "Completed" : getFirstWord(action?.name)}
            </Button>
        ) : (
            <Button
                type="primary"
                disabled={isCompleted}
                onClick={() => {
                  window.open(action.link)
                  handleClickAction()
                }}
            >
              {isCompleted ? "Completed" : getFirstWord(action?.name)}
            </Button>
        )}
      </div>
  );
}
