import { instance } from "api";
import { AxiosResponse } from "axios";
import { ActionType } from "./leagues";

export const usersApi = {
    async getEnergy(userId: number, leagueId: number): Promise<number> {
        const response: AxiosResponse<GetEnergyResponse> =
            await instance.get<GetEnergyResponse>(
                `users/energy?user_id=${userId}&league_id=${leagueId}`
            );
        return response.data.result;
    },

    async updateEnergy(
        user_id: number,
        league_id: number,
        league_season_id: number
    ): Promise<GameValue> {
        const response: AxiosResponse<UpdateGameValueResponse> =
            await instance.put<UpdateGameValueResponse>("users/energy", {
                user_id,
                league_id,
                league_season_id,
            });
        return response.data.result;
    },

    async getCommonScores(userId: number) {
        const response = await instance.get<{ result: CommonScore }>(
            `users/common_score?user_id=${userId}`
        );
        return response.data.result;
    },

    async getScores(userId: number) {
        const { data } =
            await instance.get<GetScoresResponse>(`users/score?user_id=${userId}`);
        return data
    },

    async isFirstPlayOnLeague(
        userId: number,
        leagueId: number,
        seasonId: number,
    ): Promise<AboutLeagueType> {
        const response: AxiosResponse<IsFirstPlayOnLeagueResponse> =
            await instance.get<IsFirstPlayOnLeagueResponse>(
                `users/in_league?user_id=${userId}&league_id=${leagueId}&league_season_id=${seasonId}`
            );
        return response.data.result;
    },

    async getCompletedActions(leagueId: number, userId: number) {
        const response = await instance.get<GetCompletedActionsResponse>(
            `users/actions?league_id=${leagueId}&user_id=${userId}`
        );
        return response.data.result;
    },

    async getRefs(userId: number) {
        const response = await instance.get<{ result: GetRefs[] }>(
            `users/refs?user_id=${userId}`
        );
        return response.data.result;
    },

    async getRefLink(userId: number, leagueId: number) {
        const response = await instance.get(
            `users/ref_link?user_id=${userId}&league_id=${leagueId}`
        );
        return response.data.result;
    },

    async getUserData(userId: number) {
        const response = await instance.get<GetUserDataResponse>(
            `users?user_id=${userId}`
        );
        return response.data.result;
    },

    async setEnergyAmount(
        user_id: number,
        league_id: number,
        league_season_id: number,
        amount: number
    ): Promise<GameValue> {
        const response: AxiosResponse<UpdateGameValueResponse> =
            await instance.put<UpdateGameValueResponse>("users/energy/remaining", {
                user_id,
                league_id,
                league_season_id,
                amount,
            });
        return response.data.result;
    },

    async performAction(action_id: number, user_id: number, league_id: number) {
        const response = await instance.post(
            "users/actions",
            {action_id, user_id, league_id},
        )
        return response.data.result;
    },
};

export interface CommonScore {
    friends: number
    totalPoints: number
    totalScore: number
}

// getEnergy
type GetEnergyResponse = {
    result: number;
};

type UpdateGameValueResponse = {
    result: GameValue;
};

type GameValue = {
    score: number;
    energy: number;
};

// isFirstPlayOnLeague
type IsFirstPlayOnLeagueResponse = {
    result: AboutLeagueType;
};

export type AboutLeagueType = {
    id: number;
    league_id: number;
    league_season_id: number;
    score: number;
    created_at: string;
    updated_at: string;
    user_id: 393067183;
};

// /getScore

type GetScoresResponse = {
    result: ScoresType[];
    totalUsers: number;
};

export type ScoresType = {
    score: number;
    position: number;
    count: number;
    league_id: number;
};

type GetCompletedActionsResponse = {
    result: ActionType[];
};

type GetUserDataResponse = {
    result: GetUserData;
};

export type GetUserData = {
    id: number;
    username?: string | null;
    ref_id: number;
    name?: null | string;
    avatar?: string | null;
};

type GetRefs = {
    id: number;
    username: string;
};
