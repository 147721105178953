import {FC, MouseEventHandler, ReactNode} from 'react';
import s from './Button.module.scss'
import classNames from "classnames";

const Button: FC<IProps> = ({ className, type, onClick, children, disabled }) => {
    return (
        <button
            className={classNames(
                s.btn,
                { [s.primary]: type === 'primary' },
                { [s.secondary]: type === 'secondary' },
                className,
            )}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

export default Button

interface IProps {
    className?: string
    type: 'primary' | 'secondary'
    onClick: MouseEventHandler<HTMLButtonElement>
    children: ReactNode
    disabled?: boolean
}
