import s from "./Energy.module.scss";
import { icons } from "../../../icons/icons";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { FC, useState } from "react";
import Popup from "../../../components/Popup/Popup";
import {
    useConfirmShareStoryMutation,
    useGetIsSharedStoryQuery,
} from "../../../store/services/user";

const tg = window.Telegram.WebApp;
const isUserPremium = tg?.initDataUnsafe?.user?.is_premium as boolean | undefined
const userId = tg?.initDataUnsafe?.user?.id as number

const Energy: FC<IProps> = ({ energy, totalPlayers, refLink, leagueId }) => {
    const progress = (energy / 2000) * 100

    const { data: isSharedStory } = useGetIsSharedStoryQuery({ user_id: userId })
    const [confirmShareStory] = useConfirmShareStoryMutation()

    const [isShareStory, setIsShareStory] = useState(false)

    const shareStory = () => {
        if (!isUserPremium) {
            setIsShareStory(true)
        } else {
            confirmShareStory({ user_id: userId, league_id: leagueId })
            tg?.shareToStory(
                'https://api.clicktothesky.com/static/story.mp4',
                { widget_link: { url: refLink } },
            )
        }
    }

    return (
        <div className={s.container}>
            <div className={s.top}>
                <div className={s.total}>
                    <span>Total Players</span>
                    <p>{totalPlayers?.toLocaleString()}</p>
                </div>
                <div className={s.proportion}>
                    {icons.navigation.boost}
                    {energy}/2000
                </div>
            </div>

            <div className={s.progressbar}>
                <div style={{width: progress + '%'}}/>
            </div>

            <div className={s.bottom}>
                <Link to="/how-it-works" className={s.link}>How it works</Link>
                <Button
                    type="primary"
                    onClick={shareStory}
                    className={s.btn}
                    disabled={!!isSharedStory?.result}
                >
                    Share for 5000 $CLIK!
                </Button>
            </div>

            <Popup active={isShareStory} setActive={setIsShareStory}>
                <div className={s.popup}>
                    <img src="/tapper/tg-premium.png" alt="tg-premium"/>
                    <h1>Unlock with Telegram Premium</h1>
                    <p>Subscribe to Telegram Premium to access this feature and enhance your experience.</p>
                </div>
            </Popup>
        </div>
    );
}

export default Energy

interface IProps {
    energy: number
    totalPlayers: number
    refLink: string
    leagueId: number
}
