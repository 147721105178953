import axios from "axios";

const { initData } = window.Telegram.WebApp;

let headers: object = { InitData: initData }

if (process.env.NODE_ENV === "development") {
  const testBot = process.env.REACT_APP_TEST_BOT
  headers = { ...headers, testBot }
}

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
});

// const { initDataRaw } = retrieveLaunchData();
