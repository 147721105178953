import React from 'react';
import s from './Roadmap.module.scss'
import classNames from "classnames";
import {icons} from "../../icons/icons";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

const Roadmap = () => {
    const navigate = useNavigate()

    const roadmap = [
        { title: "Pre-Launch", date: "Sept 2024", isDone: true },
        { title: "Referrals", date: "Sept 2024", isDone: true },
        { title: "Earn Tasks", date: "Oct 2024", isDone: true },
        { title: "Daily Rewards", date: "Oct 2024", isDone: true },
        { title: "Basic Game", date: "Oct 2024", isDone: true },
        { title: "Splitting Pool Technology", date: "Oct 2024", isDone: true },
        { title: "Auto Mining Rewards", date: "Nov 2024", isDone: false },
        { title: "Rank Ratings", date: "Nov 2024", isDone: false },
        { title: "Airdrop", date: "Dec 2024", isDone: false },
        { title: "PVP Battles", date: "Dec 2024", isDone: false },
        { title: "Web Game", date: "Dec 2024", isDone: false },
        { title: "$CLIK Token launch", date: "Dec 2024", isDone: false },
        { title: "Listing on Exchanges", date: "Jan 2025", isDone: false },
    ]

    return (
        <div className={s.roadmap}>
            <h1>Roadmap</h1>
            <div className={s.list}>
                {roadmap.map((item, i) => (
                    <div
                        key={item.title}
                        className={classNames(
                            s.item,
                            { [s.current]: item.isDone && !roadmap[i + 1].isDone },
                        )}
                    >
                        <div className={classNames(s.checkbox, { [s.checked]: item.isDone })}>
                            {item.isDone && icons.check}
                        </div>
                        <p>{item.title}</p>
                        <span>{item.date}</span>
                    </div>
                ))}
            </div>
            <div className={s.actions}>
                <Button type="primary" onClick={() => navigate('/tapper')}>
                    Play now
                </Button>
                <Button type="secondary" onClick={() => navigate('/how-it-works')}>
                    How it works
                </Button>
            </div>
        </div>
    )
}

export default Roadmap