import { instance } from "api"
import { AxiosResponse } from "axios"

export const leaguesApi = {
    async getLeagues() {
        const response: AxiosResponse<GetLeaguesResponse> = await instance.get("leagues");
        return response.data.result;
    },

    async getActions(leagueId: number) {
        const response: AxiosResponse<GetActionsResponse> =
            await instance.get("actions?league_id=" + leagueId);
        return response.data.result;
    },

    async getActionsLinks(userId: number) {
        const response: AxiosResponse<{ result: ActionsLinks }>
            = await instance.get(`actions/links?user_id=${userId}`)
        return response.data.result
    },

    async joinTheLeague(userId: number, leagueId: number, seasonId: number) {
        const response = await instance.post("users/league", {
            user_id: userId,
            league_id: leagueId,
            league_season_id: seasonId,
        });
        return response.data.result;
    },
};

export type ActionsLinks = {
    [leagueId: number]: {
        completedAt: string | null
        id: number
        league_id: number
        link: string
        reward: number
    }
}

//Leagues types
type GetLeaguesResponse = {
    result: LeagueType[];
};

export type Season = {
    id: number;
    end_time: string;
    league_id: number;
    name: string;
    prizes: number[];
};

export type LeagueType = {
    id: number;
    background: null | string;
    list_cover: null | string;
    tap_image: null | string;
    seasons: Season[];
    name: string;
};
//----------------------------

// actions types

type GetActionsResponse = {
    result: ActionType[];
};

export type ActionType = {
    id: number;
    league_id: number;
    link: string;
    name: string;
    reward: number;
    code: string;
    action_id?: number;
    image: string;
};