import s from "./BoostPage.module.scss";
import InviteAction from "./Actions/InviteAction";
import FollowAction from "./Actions/FollowAction";
import ChallengeAction from "./Actions/ChallengeAction";
import { ActionType, LeagueType } from "api/leagues";

const tg = window.Telegram.WebApp;
const userId = tg?.initDataUnsafe?.user?.id as number;

export default function BoostPage({
  league,
  completedActions,
  actions,
  refLink,
}: {
  league: LeagueType | null
  completedActions: ActionType[] | []
  actions: ActionType[] | undefined
  refLink: string
}) {
  return (
      <section className={s.boost}>
        <div
            className={s.head}
            style={{
                backgroundImage: `url(${league?.list_cover}`,
            }}
        >
          <img src={league?.tap_image || ""} alt={league?.name}/>
          <p>{league?.name} League</p>
          <h1>Get Boost & Earn Points</h1>
        </div>
        <div className={s.actions}>
          {actions?.map((a) => {
            const isCompleted = !!completedActions.find(
                c => c.action_id === a.id
            )
            if (a.code === "challenge") {
              return (
                  <ChallengeAction
                      key={`${a.id} ${a.code}`}
                      isCompleted={isCompleted}
                      action={a}
                  />
              )
            } else if (a.code === "invite") {
              return (
                  <InviteAction
                      key={`${a.id} ${a.code}`}
                      isCompleted={isCompleted}
                      refLink={refLink}
                      reward={a.reward}
                  />
              )
            } else {
              return (
                  <FollowAction
                      key={`${a.id} ${a.code}`}
                      action={a}
                      isCompleted={isCompleted}
                      userId={userId}
                      refLink={refLink}
                  />
              )
            }
          })}
        </div>
      </section>
  );
}
