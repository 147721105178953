import { useState, useEffect, memo } from "react";
import Timer from "./UI/UI";

type CountdownTimerProps = {
  className?: string;
  targetDate: string | null;
};

const CountdownTimer: React.FC<CountdownTimerProps> = memo(
  ({ className, targetDate }) => {
    const [timeLeft, setTimeLeft] = useState<number>(0);

    useEffect(() => {
      if (!targetDate) return;
      const intervalId = setInterval(() => {
        const now = new Date().getTime();
        const target = new Date(targetDate).getTime(); // Преобразуем строку в объект Date
        const distance = target - now;
        setTimeLeft(distance);
      }, 1000);

      return () => clearInterval(intervalId);
    }, [targetDate]);

    // Вычисляем дни, часы, минуты и секунды
    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    // Проверка на завершение отсчета
    if (timeLeft < 0) {
      return <Timer className={className} days={0} hours={0} minutes={0} seconds={0} />
    }

    return <Timer className={className} days={days} hours={hours} minutes={minutes} seconds={seconds} />
  }
);

export default CountdownTimer;
