import 'swiper/css';
import s from './HowItWorks.module.scss'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper/modules'
import Button from "../../components/Button/Button";
import imgStep1 from './images/1.png'
import imgStep2 from './images/2.png'
import imgStep3 from './images/3.png'
import imgStep4 from './images/4.png'
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

const HowItWorks = () => {
    const navigate = useNavigate()
    const pagination = ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Tips']

    const steps = [
        {
            img: imgStep1,
            title: 'Click',
            text: 'The task is simple - you can choose a league you want to join in, then tap on the screen',
        },
        {
            img: imgStep2,
            title: 'Earn Points',
            text: 'Earn points on every click you make in each league played. Points will rank you up towards monthly cash prizes. In addition each point also converts into $CLIK tokens for future airdrops.',
        },
        {
            img: imgStep3,
            title: 'Boost Task',
            text: 'ou can earn extra points by completing all boost tasks within each league. Invite a friend, boost community, earn more points!',
        },
        {
            img: imgStep4,
            title: 'Earn Rewards',
            text: 'At the end of each season/month players will be rewarded cash prizes based on their league scores. In addition every player will also get $CLIK tokens airdropped based on total points collected.',
        }
    ]

    return (
        <div className={s.page}>
            <h1>How it works?</h1>
            <div className={s.pagination} />
            <Swiper
                slidesPerView={1}
                modules={[Pagination]}
                pagination={{
                    el: '.' + s.pagination,
                    bulletClass: s.pagination__item,
                    bulletActiveClass: s.pagination__item_active,
                    clickable: false,
                    renderBullet: (index: number, className: string) =>
                        `<button class="${className}">${pagination[index]}<button>`,
                }}
                allowTouchMove={false}
            >
                {steps.map((step, i) => (
                    <SwiperSlide key={step.title} className={s.slide}>
                        <div className={s.image}>
                            <img src={step.img} alt={step.title}/>
                        </div>
                        <p className={s.label}>{pagination[i]}</p>
                        <p className={s.title}>{step.title}</p>
                        <p className={s.text}>{step.text}</p>
                        <ButtonNext />
                    </SwiperSlide>
                ))}
                <SwiperSlide className={classNames(s.slide, s.slide_tips)}>
                    <p className={s.label}>QUICK TIPS!!</p>
                    <p className={s.text}>1. Boost tasks earn you the most points.</p>
                    <p className={s.text}>
                        2. You can play in every league. Each has it’s own <b>cash prize pool.</b>
                    </p>
                    <p className={s.text}>
                        3. No limits to how many friends you can invite. This earns you the highest boost <b>50,000 points!</b>
                    </p>
                    <p className={s.text}>4. Check in daily. <b>New leagues</b> are always being added!</p>
                    <p className={s.text}>5. Points reset monthly for league prizes. All points are calculated towards <b>$CLIK airdrop.</b></p>
                    <Button type="secondary" onClick={() => navigate('/tapper')}>
                        Play Now
                    </Button>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

const ButtonNext = () => {
    const swiper = useSwiper()

    return (
        <Button type="primary" onClick={() => swiper.slideNext()}>Next</Button>
    )
}

export default HowItWorks
