import s from "./WalletPage.module.scss";

import {
  TonConnectButton,
  useTonAddress,
  useTonWallet,
} from "@tonconnect/ui-react";
import { CommonScore, GetUserData } from "api/users";
import { formatAddress } from "utils/formatAddress";

const WalletPage = ({
  userData,
  commonScore,
}: {
  userData: GetUserData | null;
  commonScore: CommonScore | null;
}) => {
  const address = useTonAddress(false); // Получаем сырой адрес
  const wallet = useTonWallet();

  return (
      <section className={s.wallet}>
        <div className={s.head}>
          <h1>Connect Your Wallet</h1>
          <p>
            {!address
                ? 'Choose how you want to connect it'
                : `Connected wallet : ${formatAddress(address)}`
            }
          </p>

          <TonConnectButton className={s.connectBtn}/>
        </div>

        <div className={s.statistics}>
          <h1>Statistics</h1>
            <div className={s.grid}>
                <div className={s.item}>
                    <span>Total $CLIK Token Earned:</span>
                    <p>
                        <img src="/leagues/quick-click-tap.png" alt="quick-click-tap"/>
                        {commonScore?.totalPoints || 0}
                    </p>
                </div>
                <div className={s.item}>
                    <span>Total tapped:</span>
                    <p>{commonScore?.totalScore || 0}</p>
                </div>
                <div className={s.item}>
                    <span>Friends referred:</span>
                    <p>{commonScore?.friends || 0}</p>
                </div>
            </div>
        </div>

          <div className={s.info}>
              <h1>How to Get $CLIK Tokens?</h1>
              <p>Earn points on Quick Click, and they’ll count toward the $CLIK token airdrop on Dec 23, 2024. Start
                  completing boost tasks now to maximize your $CLIK before the public launch!</p>
          <h2>Conversion:</h2>
          <p>10 points = 1 $CLIK</p>
        </div>
        <div className={s.info}>
          <h1>What’s Quick Click?</h1>
          <p>Quick Click is a play-to-earn game where you tap the screen to earn points. The player with the most points at the end of the month wins a cash prize!</p>
        </div>
        <div className={s.info}>
          <h1>What is a League?</h1>
          <p>A League lets you promote your project, business, or community. We’ll create a league where users complete tasks to earn rewards. You set the rewards and prizes for each task!</p>
        </div>
        <div className={s.info}>
          <h1>How to Earn More?</h1>
          <p>Join Quick Leagues! Each league has its own cash prize pool and special boost tasks. Check out the boost tasks in every league to maximize your earnings!</p>
        </div>
        <div className={s.info}>
          <h1>Earn More!? $500 Affiliate Commission!</h1>
          <p>Earn $500 for every new project you refer to Quick Click that starts a league. Remember, Quick Click admins will never message you first. Stay alert for impersonators! Contact support for more info.</p>
        </div>
        <div className={s.info}>
          <h1>Link to Support/Affiliate</h1>
          <a href="https://t.me/QuickClickSupport">https://t.me/QuickClickSupport</a>
        </div>
      </section>
  )
}

export default WalletPage
