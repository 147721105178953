import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './api'

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQuery,
    tagTypes: ['DailyRewards', 'Invoices', 'Story'],
    endpoints: builder => ({
        getDailyInfo: builder.query<CurrentDaily, number>({
            query: user_id => ({
                url: 'actions/daily',
                params: { user_id },
            }),
            providesTags: ['DailyRewards'],
        }),

        saveDailyReward: builder.mutation<void, { user_id: number, reward: number }>({
            query: dto => ({
                url: 'actions/daily',
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: ['DailyRewards'],
        }),

        getInvoices: builder.query<{ result: null | unknown }, { user_id: number, league_id: number }>({
            query: params => ({
                url: 'invoice',
                params,
            }),
            providesTags: ['Invoices'],
        }),

        createInvoice: builder.mutation<void, { user_id: number, league_id: number, pack: 'basic' | 'quick' }>({
            query: params => ({
                url: 'invoice',
                method: 'POST',
                params,
            }),
            invalidatesTags: ['Invoices'],
        }),

        getIsSharedStory: builder.query<{ result: null | unknown}, { user_id: number }>({
            query: params => ({
                url: 'users/actions/story',
                params,
            }),
            providesTags: ['Story'],
        }),

        confirmShareStory: builder.mutation<void, { user_id: number, league_id: number }>({
            query: dto => ({
                url: 'users/actions/story',
                method: 'POST',
                body: dto,
            }),
            invalidatesTags: ['Story'],
        }),
    }),
})

export const {
    useGetDailyInfoQuery,
    useSaveDailyRewardMutation,
    useGetInvoicesQuery,
    useCreateInvoiceMutation,
    useGetIsSharedStoryQuery,
    useConfirmShareStoryMutation,
} = userApi

export interface CurrentDaily {
    next_reward: number
    result: {
        user_id: number
        date: string
        reward: number
        created_at: string
        updated_at: string
    } | null
}
